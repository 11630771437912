













































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const model = reactive({
      payu: "",
      polishBank: "",
      englishBank: "",
    });

    const state = reactive({
      empty: false,
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      payU: "",
      bank: [],
      payUSelected: [] as any,
      bankSelected: [] as any,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("payment-credentials", { params: { active: true } })
        .then(({ data: { paymentCredentials } }) => {
          state.empty = false;
          state.payU = paymentCredentials.filter(
            (obj: any) => obj.type == "PayU"
          );
          state.bank = paymentCredentials.filter(
            (obj: any) => obj.type == "Transfer"
          );
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const fetchMethods = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/payment-methods`)
        .then(({ data }) => {
          state.empty = false;
          model.payu = data.paymentCredentials[0]?.id || null;
          model.polishBank = data.bankAccountPl?.id || null;
          model.englishBank = data.bankAccountEn?.id || null;
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            state.empty = true;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchMethods);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.misc.methodExist")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        bankAccountPlId: model.polishBank || null,
        bankAccountEnId: model.englishBank || null,
        payuId: model.payu || null,
      };

      state.loading = true;

      axiosInstance
        .put(
          `/event/${root.$route.params.id}/payment-credentials/assign`,
          data,
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("event.panel.paymentMethod.edit.success"),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { model, state, onSubmit, getErrorMessage };
  },
});
